<template>
  <div>
    <CDPEListe />
  </div>
</template>
<script>
import CDPEListe from '../../components/cdpe/Liste';

export default {
  name: 'CDPESearchContainer',
  components: {
    CDPEListe,
  },
};
</script>
