var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',{staticClass:"pa-5 mb-5"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"light":"","headers":_vm.headers,"items":_vm.cdpe,"search":_vm.search,"sort-by":['code'],"items-per-page":50,"footer-props":{
        itemsPerPageText: 'CDPE par page:',
        itemsPerPageOptions: [20, 50, 100, 200]
      }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" more_vert ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'editer-cdpe', params: { cdpeId: item.id }}}},[_c('v-list-item-action',{key:item.id},[_vm._v(" Modifier ")])],1)],1)],1)]}}])},[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}],null,false,63074868)}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }