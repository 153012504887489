<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        light
        :headers="headers"
        class="elevation-3"
        :items="cdpe"
        :search="search"
        :sort-by="['code']"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageText: 'CDPE par page:',
          itemsPerPageOptions: [20, 50, 100, 200]
        }"
      >
        <template v-slot:item.action="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click.stop
              >
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'editer-cdpe', params: { cdpeId: item.id }}"
              >
                <v-list-item-action
                  :key="item.id"
                >
                  Modifier
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-progress-linear
          v-if="loading"
          v-slot:progress
          color="primary"
        />
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex';

  export default {
    name: 'CDPEListe',
    data: () => ({
      currentCdpeId: 0,
      search: '',
      headers: [
        {
          text: 'Action',
          sortable: false,
          value: 'action',
        },
        {
          text: 'Code',
          sortable: true,
          value: 'code',
        },
        {
          text: 'Nom',
          sortable: true,
          value: 'nom',
        },
      ],
  }),

  computed: {
    ...mapState('cdpe', {
      loading: state => state.loading,
    }),
    ...mapActions('cdpe', [
      'loadAllDepartements',
    ]),
    ...mapGetters('cdpe', {
      cdpe: 'getFilterDepartements',
    }),
  },

  created() {
  },

  methods: {
    onButtonClick(item) {
      this.$router.push( { name: 'editer-cdpe', params: { cdpeId: item.id } } );
    },
  },
};
</script>
